import Tilt from 'react-parallax-tilt';
import './Logo.css';
import smartbrain from './smartbrain.png'

 const Logo = () => {
	return (
		<div className='ma4 mt0'>
			<Tilt className="Tilt br2 shadow-2" style={{height: 150, width: 150}}>
				<div className='pa2'><img className = 'img-hor-vert' src={smartbrain} alt="logo"/></div>
			</Tilt>
		</div>
	);
};

export default Logo;