const Footer = () => {
	return(
		<div className='pa3 w-100 center mt2'>
			<p>
				<a href="https://www.freepik.com/icon/artificial-intelligence_900961#fromView=keyword&page=1&position=3&uuid=3b611197-7716-4638-aed5-7fc9edf891fb">Icon by Freepik</a>
			</p>
		</div>
	);
};

export default Footer;