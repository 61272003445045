import './App.css';
import { useState } from 'react';
import Navigation from './components/Navigation/Navigation.js';
import FaceRecognition from './components/FaceRecognition/FaceRecognition.js'
import Logo from './components/Logo/Logo.js';
import SignIn from './components/SignIn/SignIn.js'
import Register from './components/Register/Register.js'
import Footer from './components/Footer/Footer.js';
import ImageLinkForm from './components/ImageLinkForm/ImageLinkForm.js';
import Rank from './components/Rank/Rank.js';
import ParticlesBg from 'particles-bg';

function App() {
  
  // State variables
  const [input, setInput] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [boxes, setBoxes] = useState([]);
  const [route, setRoute] = useState('signin');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [user, setUser] = useState(
    {
      id: '',
      name: '',
      email: '', 
      entries: 0,
      joined: ''
    }
  );

  const calculateFaceLocation = (bbox) => {
    const image = document.getElementById('inputimage');
    const width = Number(image.width);
    const height = Number(image.height);
    return {
      leftCol: bbox.left_col * width,
      topRow: bbox.top_row * height,
      rightCol: width - (bbox.right_col * width),
      bottomRow: height - (bbox.bottom_row * height)
    }
  }

  const displayFaceBox = (boxArray) => {
    setBoxes(boxArray);
  }

  const onRouteChange = (newRoute) => {
    setRoute(newRoute);
    setInput('');
    setImageURL('');
    if (newRoute === 'signin'){
      setIsSignedIn(false)
    } else if (newRoute === 'home'){
      setIsSignedIn(true)
    }
  }

  const onInputChange = (event) => {
    setInput(event.target.value);
  }

  const onButtonSubmit = async () => {
    setImageURL(input);
    let bBoxArray = [];

    bBoxArray = await fetch('https://lettucenrg.com/imageurl', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
        input: input
      }) 
    })
      .then(response => response.json())
      .catch(console.log)

    bBoxArray = bBoxArray.map(calculateFaceLocation);

    displayFaceBox(bBoxArray);

    fetch('https://lettucenrg.com/image', {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
            id: user.id,
            boxCount: bBoxArray.length
          }) 
        })
        .then(response => response.json())
        .then((newEntries) => setUser({...user, entries: newEntries}))
        .catch(console.log)
  }

  return (
    <div className="App">
      <Navigation isSignedIn={isSignedIn} onRouteChange={onRouteChange}/>
      {route === 'home' 
        ? <div>
            <Logo />
            <Rank userName = {user.name} userEntries = {user.entries}/>
            <ImageLinkForm 
              onInputChange={onInputChange}
              onButtonSubmit={onButtonSubmit}
            />
            <FaceRecognition boxes={boxes} imageURL={imageURL}/>
            <Footer />
          </div>
        : (
          route === 'signin'
          ? <SignIn onRouteChange={onRouteChange} setUser={setUser} />
          : <Register onRouteChange={onRouteChange} setUser={setUser} />
          )
           
        }
      <ParticlesBg color='#ffffff' type='cobweb' bg={true} />
    </div>
  );
}

export default App;
