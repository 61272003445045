const Rank = ({userName, userEntries}) => {
	return (
		<div>
			<div className = 'white f3'>
				{`${userName}, the number of faces you have detected is...`}
			</div>
			<div className='white f1'>
				{userEntries}
			</div>
		</div>
	);
};

export default Rank;